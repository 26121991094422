<template>
  <div
    ref="divRef"
    class="bg-image d-flex-center flex-column"
    :style="`
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${backgroundFlightSearchUrl});
      min-height: ${heightCard};
    `"
  >
    <div class="search-flight-modal d-flex flex-column justify-content-start align-items-center">
      <div class="w-100 d-flex-between">
        <SearchSwitchTypeCheckbox :type.sync="searchData.type" />
        <b-button
          v-if="isShowResetSearch"
          variant="flat-dark"
          class="p-50 mb-50"
          @click="handleResetSearch"
        >
          <feather-icon
            class="cursor-pointer"
            icon="RotateCwIcon"
            size="20"
          />
        </b-button>
      </div>
      <oneWay
        v-if="searchData.type === 'OW'"
        :search-data.sync="searchData"
        :is-disabled-permitted-cariers="isDisabledPermittedCariers"
        @swap-from-to="swapHandle"
      />
      <roundTrip
        v-if="searchData.type === 'RT'"
        :search-data.sync="searchData"
        :is-disabled-permitted-cariers="isDisabledPermittedCariers"
        :isDisabledGroupedItinerary="isDisabledGroupedItinerary"
        @swap-from-to="swapHandle"
      />
      <multiCity
        v-if="searchData.type === 'MC'"
        :search-data.sync="searchData"
        :is-disabled-permitted-cariers="isDisabledPermittedCariers"
        :isDisabledGroupedItinerary="isDisabledGroupedItinerary"
        @swap-from-to="swapHandle"
      />

      <div class="w-100 d-flex justify-content-center align-items-center mt-0">
        <b-button
          v-ripple.400
          style="background-color: #1A5A7E !important;"
          pill
          :disabled="!isActiveSearchButton"
          @click="handleSearchButton"
        >
          <div class="d-flex align-items-center">
            <img
              src="@icons/search.svg"
              alt="Search"
              class="mr-25 ico-bold"
            >
            {{ $t('flight.searchFlight') }}
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref, onUnmounted, watch, computed, onMounted,
} from '@vue/composition-api'
import { isEmpty, isNil } from 'lodash'
import { BButton } from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

import store from '@/store'
import { useRouter } from '@/@core/utils/utils'
import { defaultDeparture, defaultArrival } from '@/constants/flight'
import { isDomesticFlight } from '@/constants/selectOptions'
import env from '@/libs/env'

import { convertISODateTime } from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'
import storeModule from '@flightv2/bookingStoreModule'
import oneWay from '@flightv2/search/one-way.vue'
import roundTrip from '@flightv2/search/round-trip.vue'
import multiCity from '@flightv2/search/multi-city.vue'

import useToast from '@useToast'

import SearchSwitchTypeCheckbox from './components/SearchSwitchTypeCheckbox.vue'

export default {
  components: {
    BButton,
    oneWay,
    roundTrip,
    multiCity,
    SearchSwitchTypeCheckbox,
  },
  setup(_, { root }) {
    const { toastError } = useToast()
    const {
      fetchAirportGroup,
      FLIGHT_APP_STORE_MODULE_NAME,
      resetStore,
      getAirportsByCodeList,
    } = useBookingHandle()

    if (!store.hasModule(FLIGHT_APP_STORE_MODULE_NAME)) {
      store.registerModule(FLIGHT_APP_STORE_MODULE_NAME, storeModule)
    }
    resetStore(true)
    onUnmounted(() => {
      if (store.hasModule(FLIGHT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(FLIGHT_APP_STORE_MODULE_NAME)
      }
    })

    onMounted(async () => {
      const distributorOptions = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getDistributorOptions`])
      if (isEmpty(distributorOptions.value)) {
        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setDistributorOptions`)
      }
    })

    const { router } = useRouter()
    const employeeConfig = computed(() => store.getters['userStore/getEmployeeConfig'])

    const blankSearchData = {
      adult: 1,
      child: 0,
      infant: 0,
      type: 'OW',
      currency: 'VND',
      numberOfStop: 0,
      flights: [
        {
          startPoint: null,
          endPoint: null,
          departDate: convertISODateTime(new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1))).dateFilter,
          returnDate: convertISODateTime(new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 4))).dateFilter,
        },
      ],
      airlines: [],
      promoCodes: [], // 'TESTBB01'
      isSearchClassBooking: false,
      isSearchMonthlyCheapestFare: false,
      cabinPreference: undefined,
      contentSource: undefined,
      isGroupedItineraryResponse: true,
      permittedCarriers: [],
    }
    const searchData = ref(cloneDeep(blankSearchData))
    const searchFlightPrev = JSON.parse(localStorage.getItem('searchFlight'))
    if (searchFlightPrev) {
      if (!searchFlightPrev.flights[0].departDate) {
        searchFlightPrev.flights[0].departDate = convertISODateTime(new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1))).dateFilter
      }
      // handle case chọn OW => lưu localStorage => chuyển sang RT ko có returnDate
      if (!searchFlightPrev.flights[0].returnDate) {
        searchFlightPrev.flights[0].returnDate = convertISODateTime(new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 4))).dateFilter
      }
      // handle contentSource
      if (searchFlightPrev.contentSource) {
        searchFlightPrev.airlines.push(searchFlightPrev.contentSource)
        searchFlightPrev.contentSource = undefined
      }
      // handle case ngày lưu trong localStorage < ngày hiện tại => gán = ngày hiện tại + 1
      searchFlightPrev.flights.forEach(trip => {
        if (new Date(trip.departDate) - new Date() < 0) {
          trip.departDate = convertISODateTime(new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1))).dateFilter
        }
        if (new Date(trip.returnDate) - new Date() < 0) {
          trip.returnDate = convertISODateTime(new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1))).dateFilter
        }
      })

      if (searchFlightPrev.adult === 0) {
        searchFlightPrev.adult = 1
      }

      if (searchFlightPrev.permittedCarriers) {
        searchFlightPrev.permittedCarriers = []
      }

      if (searchFlightPrev.isSearchClassBooking && (!searchFlightPrev.airline || !['VN1A', 'VU', '1G'].includes(searchFlightPrev.airline))) {
        searchFlightPrev.airline = ''
        searchFlightPrev.airlines = []
        searchFlightPrev.isSearchClassBooking = false
      }

      searchData.value = cloneDeep(searchFlightPrev)
    }

    if (isEmpty(store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getAirportGroup`])) { fetchAirportGroup() }

    if (!searchData.value.flights[0].startPoint) {
      searchData.value.flights[0].startPoint = defaultDeparture
    }

    if (!searchData.value.flights[0].endPoint) {
      searchData.value.flights[0].endPoint = defaultArrival
    }

    async function fetchConfigSearch(codes) {
      await getAirportsByCodeList(codes).then(response => {
        searchData.value.flights[0].startPoint = response.data.find(point => point.iata === codes[0])
        searchData.value.flights[0].endPoint = response.data.find(point => point.iata === codes[1])
      })
    }

    watch(employeeConfig, async (config, oldConfig) => {
      if (!searchFlightPrev && !oldConfig && config?.departureCode && config?.arrivalCode) {
        await fetchConfigSearch([config.departureCode, config.arrivalCode])
      }
    }, { deep: true, immediate: true })

    async function handleResetSearch() {
      root.$emit('bv::show::modal', 'modal-api-loading')
      searchData.value = cloneDeep(blankSearchData)
      await fetchConfigSearch([employeeConfig.value.departureCode, employeeConfig.value.arrivalCode])
      localStorage.setItem('searchFlight', JSON.stringify(searchData.value))
      setTimeout(() => {
        root.$emit('bv::hide::modal', 'modal-api-loading')
      }, 300)
    }

    function swapHandle(index = 0) {
      const temp = searchData.value.flights[index].startPoint
      searchData.value.flights[index].startPoint = searchData.value.flights[index].endPoint
      searchData.value.flights[index].endPoint = temp
    }

    const searchV2Data = ref(null)

    const defaultSearchClassBookingData = {
      airlines: ['VU'],
      numberOfStop: 0,
      isSearchMonthlyCheapestFare: false,
      isGroupedItineraryResponse: false,
    }

    const defaultSearchMonthlyCheapestData = {
      airlines: ['VN', 'VJ', 'QH', 'VU'],
      // numberOfStop: 1,
      isSearchClassBooking: false,
      isGroupedItineraryResponse: false,
    }

    const { backgroundFlightSearchUrl, isProduction } = env
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    watch(() => searchData.value.isSearchClassBooking, val => {
      if (val) { // true
        searchV2Data.value = {
          airlines: searchData.value.airlines,
          numberOfStop: searchData.value.numberOfStop,
          isGroupedItineraryResponse: searchData.value?.isGroupedItineraryResponse,
        }

        const isDomestic = searchData.value.flights.every(item => isDomesticFlight(item.startPoint?.iata || item.startPoint, item.endPoint?.iata || item.endPoint))

        searchData.value = {
          ...searchData.value,
          ...defaultSearchClassBookingData,
          numberOfStop: isDomestic ? 0 : 99,
          airlines: isDomestic ? ['VN1A'] : (!isProduction || (isProduction && !isRoleF3.value)) ? ['1G'] : ['VN1A'],
        }
      } else if (searchV2Data.value && !val) {
        searchData.value = {
          ...searchData.value,
          ...searchV2Data.value,
          airlines: [],
          airline: '',
        }
      }
    }, { immediate: true })

    watch(() => searchData.value.isSearchMonthlyCheapestFare, val => {
      if (val) {
        searchV2Data.value = {
          airlines: searchData.value.airlines,
          // numberOfStop: searchData.value.numberOfStop,
          isGroupedItineraryResponse: searchData.value?.isGroupedItineraryResponse,
        }

        searchData.value = {
          ...searchData.value,
          ...defaultSearchMonthlyCheapestData,
        }
      } else if (searchV2Data.value && !val) {
        searchData.value = {
          ...searchData.value,
          ...searchV2Data.value,
        }
      }
    }, { immediate: true })

    watch(() => searchData.value.isGroupedItineraryResponse, val => {
      if (val && (searchData.value.isSearchClassBooking || searchData.value.isSearchMonthlyCheapestFare)) {
        searchData.value.isSearchClassBooking = false
        searchData.value.isSearchMonthlyCheapestFare = false
      }
    }, { immediate: true })

    function modifySearchData(searchData) {
      searchData.airlines = searchData.airlines ?? []
      const hasSelectValidSource = searchData?.airlines?.some(al => !!al && ['1G', '1A', 'VN1A'].includes(al))

      // handle contentSource for 1G
      if (searchData.airlines.includes('GDS') && searchData.airlines.includes('NDC')) {
        searchData.airlines = searchData.airlines.filter(item => item !== 'GDS' && item !== 'NDC')
        searchData.contentSource = undefined
        if (!hasSelectValidSource) searchData.airlines.push('1G')
      } else if (searchData.airlines.includes('GDS')) {
        searchData.airlines = searchData.airlines.filter(item => item !== 'GDS')
        searchData.contentSource = 'GDS'
        if (!hasSelectValidSource) searchData.airlines.push('1G')
      } else if (searchData.airlines.includes('NDC')) {
        searchData.airlines = searchData.airlines.filter(item => item !== 'NDC')
        searchData.contentSource = 'NDC'
        if (!hasSelectValidSource) searchData.airlines.push('1G')
      }

      // NOTE: OW, vé rẻ tháng bỏ giá kết hợp
      // if (searchData.type === 'OW' || searchData.value?.isSearchMonthlyCheapestFare) {
      //   searchData.isGroupedItineraryResponse = false
      // }

      if (searchData.type === 'OW' || searchData.type === 'RT') {
        searchData.flights.splice(1)
      }
      if (!searchData?.airlines && searchData.airline) {
        searchData.airlines = [searchData.airline]
      }
      const payload = { ...JSON.parse(JSON.stringify(searchData)) }
      payload.airlines = searchData.airlines.map(item => item.toUpperCase())
      payload.flights = payload.flights.map((item, indexFlight) => {
        if ((!item?.returnDate || !item?.departDate
          || (moment(item?.returnDate) - moment().subtract(1, 'day') < 0)
          || (moment(item?.departDate) - moment().subtract(1, 'day') < 0))
          && indexFlight === 0 && payload.type === 'RT') {
          toastError('messagesList["Please select departure date / arrival date"]')
          throw Error('messagesList["Please select departure date / arrival date"]')
        }
        if (!(item?.startPoint?.iata || item?.startPoint) || !(item?.endPoint?.iata || item?.endPoint)) {
          toastError('messagesList["Please select departure / destination"]')
          throw Error('messagesList["Please select departure / destination"]')
        }
        // eslint-disable-next-line consistent-return
        return {
          ...item,
          startPoint: item?.startPoint?.iata || item?.startPoint,
          endPoint: item?.endPoint?.iata || item?.endPoint,
        }
      })

      return JSON.stringify(payload)
    }

    function modifySearchClassBooking(searchData) {
      if (searchData.type === 'OW' || searchData.type === 'RT') {
        searchData.flights.splice(1)
      }

      const payload = {
        airline: searchData.airlines.some(i => ['VU'].includes(i)) ? ['VU'] : [searchData.airlines[0]],
        airlines: [searchData.airlines[0]],
        adult: searchData.adult,
        child: searchData.child,
        infant: searchData.infant,
        type: searchData.type,
        isGroupedItineraryResponse: searchData.isGroupedItineraryResponse,
        isSearchMonthlyCheapestFare: searchData.isSearchMonthlyCheapestFare,
        isSearchClassBooking: searchData.isSearchClassBooking,
        numberOfStop: searchData?.numberOfStop || 0,
        flights: searchData.flights.map(i => ({
          startPoint: i.startPoint?.iata || i.startPoint,
          endPoint: i.endPoint?.iata || i.endPoint,
          departDate: convertISODateTime(i.departDate).dateFilter,
          ...((searchData.type === 'RT') && { returnDate: convertISODateTime(i.returnDate).dateFilter }),
        })),
        nextResultReference: '',
        currency: 'VND',
      }

      if (searchData.airlines?.includes('1G')) {
        if (!isEmpty(searchData?.permittedCarriers)) {
          payload.permittedCarriers = searchData.permittedCarriers
        }

        payload.source = '1G'
      } else if (searchData.airlines?.includes('VN1A')) {
        // if (payload.permittedCarriers) delete payload.permittedCarriers
        payload.permittedCarriers = !isEmpty(searchData.permittedCarriers) ? searchData.permittedCarriers : undefined

        payload.isBook = true
        payload.source = 'VN1A'
      } else if (payload.source) {
        if (payload.permittedCarriers) delete payload.permittedCarriers
        delete payload.source
      }

      return JSON.stringify(payload)
    }

    function handleSearchButton() {
      if (searchData.value?.isSearchClassBooking) {
        localStorage.setItem('searchFlight', modifySearchClassBooking(searchData.value))

        router.push({
          name: 'apps-flightsV2-class-booking-result',
        })
      } else if (searchData.value?.isSearchMonthlyCheapestFare) {
        localStorage.setItem('searchFlight', modifySearchData(searchData.value))

        router.push({
          name: 'apps-flightsV2-monthly-cheap-fare-result',
        })
      } else {
        const resModify = modifySearchData(searchData.value)
        if (!resModify) return
        localStorage.setItem('searchFlight', resModify)

        router.push({
          name: 'apps-flightsV2-result',
        })
      }
    }

    const isActiveSearchButton = ref(true)
    watch(searchData.value.flights, () => {
      isActiveSearchButton.value = searchData.value.flights.every(item => {
        if (!item?.departDate || (!item?.returnDate && ['RT'].includes(searchData.value?.type))) {
          return false
        }
        const sPoint = item?.startPoint?.iata || item?.startPoint
        const ePoint = item?.endPoint?.iata || item?.endPoint

        if (sPoint && ePoint && (sPoint === ePoint)) {
          toastError({
            title: 'messagesList["Departure and destination must be difference"]',
          })
          return false
        }
        return true
      })

      const isDomestic = searchData.value.flights.every(item => isDomesticFlight(item.startPoint?.iata || item.startPoint, item.endPoint?.iata || item.endPoint))
      const hasVCS = searchData.value.flights.some(item => [item.startPoint?.iata || item.startPoint, item.endPoint?.iata || item.endPoint].includes('VCS'))

      if ((!isDomestic && searchData.value.numberOfStop !== 99) || hasVCS) {
        searchData.value.numberOfStop = 99
      } else if (isDomestic && searchData.value.numberOfStop !== 0) {
        searchData.value.numberOfStop = 0
      }

      // searchData.value.isGroupedItineraryResponse = (!['OW'].includes(searchData.value.type) && !searchData.value.isSearchClassBooking) ? !isDomestic : false

      if (!isDomestic && searchData.value.isSearchClassBooking) {
        if (!isProduction || (isProduction && !isRoleF3.value)) {
          searchData.value.airlines = ['1G']
        }
      }

      if (isDomestic && searchData.value.isSearchClassBooking && searchData.value.airlines.includes('1G')) {
        searchData.value.airlines = ['VN1A']
      }
    }, { deep: true, immediate: true })

    const airlinesLength = computed(() => searchData.value?.airlines?.length || 0)

    // const isDisabledGroupedItinerary = computed(() => {
    //   // const isDomestic = searchData.value.flights.every(item => isDomesticFlight(item.startPoint?.iata || item.startPoint, item.endPoint?.iata || item.endPoint))
    //   const checkAirlinesHas1G = airlinesLength.value && !['1G', 'GDS', 'NDC', '1A', 'VN1A'].some(item => searchData.value.airlines.includes(item))
    //   if (// isDomestic ||
    //     checkAirlinesHas1G) {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     searchData.value.isGroupedItineraryResponse = false
    //     return true
    //   }
    //   return false
    // })

    const isDisabledGroupedItinerary = ref(false)

    const isDisabledPermittedCariers = computed(() => {
      const isDomestic = searchData.value.flights.every(item => isDomesticFlight(item.startPoint?.iata || item.startPoint, item.endPoint?.iata || item.endPoint))
      const checkAirlinesHas1G = airlinesLength.value && !['1G', 'GDS', 'NDC', '1A', 'VN1A'].some(item => searchData.value.airlines.includes(item))

      if (searchData.value.isSearchClassBooking && searchData.value?.airlines?.some(a => ['VN1A'].includes(a))) {
        return false
      }

      if (isDomestic || checkAirlinesHas1G) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        searchData.value.permittedCarriers = []
        return true
      }
      return false
    })

    const permittedCarriersLength = computed(() => searchData.value?.permittedCarriers?.length || 0)
    watch(() => permittedCarriersLength.value, permittedCarriersLength => {
      searchData.value.airlines = permittedCarriersLength > 0 ? searchData.value?.airlines?.some(a => ['1A', 'VN1A', '1G'].includes(a)) ? searchData.value?.airlines?.filter(a => ['1A', 'VN1A', '1G'].includes(a)) : [] : []
    })

    const isShowResetSearch = computed(() => !isNil(employeeConfig.value))

    const divRef = ref()
    const heightCard = computed(() => {
      const offsetTop = divRef.value?.offsetTop ?? 0
      return offsetTop ? `calc(100vh - ${offsetTop}px - 20px)` : 'calc(100vh - 140px)'
    })
    return {
      searchData,
      swapHandle,
      modifySearchData,
      handleResetSearch,
      handleSearchButton,
      isActiveSearchButton,
      isDisabledPermittedCariers,
      isShowResetSearch,
      isDisabledGroupedItinerary,
      backgroundFlightSearchUrl,
      divRef,
      heightCard,
    }
  },
}
</script>

<style scoped lang="scss">
.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 8px;
}

.search-flight-modal {
  background: #FFFFFF33;
  backdrop-filter: blur(10px);
  border-radius: 20px;
  width: 1136px;
  max-width: calc(100% - 24px);
  padding: 40px
}

@media (max-width: 768px) {
  .search-flight-modal {
    padding: 12px
  }
}

.card {
  border-radius: 8px;
}
</style>
